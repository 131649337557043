import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Interactive = styled.div`
  height: 500px;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    height: 800px;
  }

  @media only screen and (min-width: 2200px) {
    height: 1100px;
  }
`;

const DisplayImageWrapper = styled.div`
  .room-image {
    object-position: 50% 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
`;

function SatellitePreview(): JSX.Element {
  return (
    <>
      <Interactive>
        <DisplayImageWrapper>
          <StaticImage
            className="room-image"
            src="../../images/pigi/pigi-desktop.jpg"
            alt="Room with PIGI on screen"
          />
        </DisplayImageWrapper>
      </Interactive>
    </>
  );
}

export default SatellitePreview;
