import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { Button, Typography } from 'amphitheatre';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { GrDeliver, GrPlan } from 'react-icons/gr';
import { IoMdEye, IoMdMove } from 'react-icons/io';
import { HiCode, HiOutlineDocumentReport } from 'react-icons/hi';

import Layout from 'src/Layout';

import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import IconText from 'src/Components/IconText';
import MovingBar from 'src/Components/MovingBar';
import Plan from 'src/Components/PIGIPlan';
import PIGIVideo from 'src/Components/PIGIVideo';
import SatellitePreview from 'src/Components/SatellitePreview';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';

const { Header } = Typography;

interface ColumnsProps {
  reverse?: boolean;
}

const Columns = styled.div<ColumnsProps>`
  display: flex;
  flex-flow: column;

  @media only screen and (min-width: 800px) {
    flex-flow: row;

    ${({ reverse }) => {
      if (reverse) return 'flex-direction: row-reverse;';
    }};
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  flex-basis: 50%;

  @media only screen and (min-width: 800px) {
    padding: 1rem;
  }
`;

const BtnGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    row-gap: 2rem;
  }
`;

const RocketImageWrapper = styled.div`
  .rocket-image {
    height: 396px;

    @media only screen and (min-width: 1024px) {
      height: 768px;
    }
  }
`;

const CustomHeroText = styled(Hero.Text)`
  box-sizing: border-box;

  padding-right: 0px;
  position: relative;

  @media only screen and (min-width: 1024px) {
    padding-right: 286px;
  }

  @media only screen and (min-width: 1200px) {
    padding-right: 400px;
  }
`;

const PigiLogoWrapper = styled.div`
  .pigi-logo {
    position: absolute;
    left: 0;
    top: -152px;
    height: 128px;
    width: 128px;

    @media only screen and (min-width: 1024px) {
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 256px;
      width: 256px;
    }

    @media only screen and (min-width: 1200px) {
      height: 376px;
      width: 376px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

interface Props {
  site: {
    siteMetadata: {
      rawURL: string;
    };
  };
}

function PIGI({ site }: Props) {
  const { rawURL } = site.siteMetadata;

  return (
    <>
      <SEO
        title="P.I.G.I. | Saber Astronautics"
        description="P.I.G.I. is our freestanding, prorietary space mission design and planning software which is distinctive for high fidelity 3D visualisations and ease of use."
        url="https://www.saberastro.com/pigi"
      />
      <Layout>
        <Hero.Wrapper>
          <Hero.Cover />
          <Hero.Cover />
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/pigi/exploded.jpg"
            alt="hero"
          />
          <CustomHeroText>
            <Hero.MajorHeader style={{ textAlign: 'left' }}>
              Predictive Interactive Ground-station Interface
            </Hero.MajorHeader>
            <Hero.Header style={{ textAlign: 'left', marginBottom: '1rem' }}>
              <Base.Yellow>Bring your company out of the dark ages</Base.Yellow>
            </Hero.Header>
            <ButtonGroup>
              <Link to="/pigi#plans">
                <Button fill>Buy Now</Button>
              </Link>
              <Link to="/downloads">
                <Button fill>Download</Button>
              </Link>
              <Link to="/tutorials">
                <Button fill ghost>
                  Tutorials
                </Button>
              </Link>
            </ButtonGroup>
            <PigiLogoWrapper>
              <StaticImage
                className="pigi-logo"
                src="../images/pigi/pigi.png"
                alt="hero"
              />
            </PigiLogoWrapper>
          </CustomHeroText>
        </Hero.Wrapper>
        <Container.Dark
          style={{
            position: 'relative',
            paddingTop: '2rem',
            overflow: 'hidden',
          }}
        >
          <Base.Text>
            <Base.Heading>Plan an entire mission from your laptop</Base.Heading>
          </Base.Text>
          <Base.Gap />
          <Features>
            <div>
              <IconText
                icon={<IoMdEye />}
                header="MONITOR AND CONTROL SPACECRAFT SUBSYSTEMS & PAYLOADS"
              />
              <IconText
                icon={<HiOutlineDocumentReport />}
                header="RECEIVE SPACECRAFT PERFORMANCE ANALYSIS AND REPORTING"
              />
              <IconText
                icon={<GrPlan />}
                header="CONTROL OF MISSION OPERATIONS"
              />
            </div>
            <div>
              <RocketImageWrapper>
                <StaticImage
                  className="rocket-image"
                  src="../images/pigi/rocket.png"
                  alt="rocket"
                  objectFit="contain"
                />
              </RocketImageWrapper>
            </div>
            <div>
              <IconText
                icon={<IoMdMove />}
                header="MANEUVER PREPARATION AND ORBITAL DESIGN"
              />
              <IconText
                icon={<HiCode />}
                header="MANAGEMENT OF ONBOARD SOFTWARE"
              />
              <IconText
                icon={<GrDeliver />}
                header="DELIVERY OF MISSION DATA PRODUCTS"
              />
            </div>
          </Features>
          <Base.Gap />
        </Container.Dark>
        <SatellitePreview />
        <MovingBar />
        <PIGIVideo />
        <Container.Dark>
          <Columns reverse>
            <Column>
              <StaticImage
                src="../images/pigi/realtime.jpg"
                alt="Complements real time data"
              />
            </Column>
            <Column>
              <Base.Text>
                <Header>
                  <Base.Yellow>Cloud based.</Base.Yellow>
                </Header>
                <Base.Paragraph>
                  P.I.G.I. is a standalone software using standardised
                  equipment, sitting on your desktop. Efficient and flexible for
                  modern space missions, P.I.G.I. has no external dependencies.
                  Plan your mission from your laptop!
                </Base.Paragraph>
              </Base.Text>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <StaticImage
                src="../images/pigi/sun.png"
                alt="Saber team teaching PIGI"
              />
            </Column>
            <Column>
              <Base.Text>
                <Header>
                  <Base.Yellow>Real time data.</Base.Yellow>
                </Header>
                <Base.Paragraph>
                  P.I.G.I. draws space data from Saber’s cloud. Our systems plot
                  multiple complex data points, interpreting them in-program
                  into stunning visuals optimised for accuracy.
                </Base.Paragraph>
              </Base.Text>
            </Column>
          </Columns>
          <Base.Gap />
          <Base.Text style={{ textAlign: 'center' }}>
            <Base.Heading>Already bought P.I.G.I.?</Base.Heading>
            <Base.Paragraph>
              We are dedicated to giving you the best possible experience
            </Base.Paragraph>
            <BtnGroup>
              <Link
                className="links"
                to="/tutorials"
                style={{ textDecoration: 'none' }}
              >
                <Button fill size="xlg">
                  Check our tutorials
                </Button>
              </Link>
              <Link
                className="links"
                to="/downloads"
                style={{ textDecoration: 'none' }}
              >
                <Button fill size="xlg" ghost>
                  Download
                </Button>
              </Link>
            </BtnGroup>
          </Base.Text>
          <Base.Gap />
          <Plan id="plans">
            <Plan.Columns>
              <Plan.Box>
                <Plan.Name>P.I.G.I. CASUAL</Plan.Name>
                <Plan.Cost>
                  $14.99<span className="small">/month</span>
                </Plan.Cost>
                <Plan.List>
                  <ul>
                    <li>Spacecraft and Weather Telemetry</li>
                    <li>High-speed data service</li>
                    <li>Spacecraft design Tools</li>
                    <li>Ground station control</li>
                  </ul>
                </Plan.List>
                <a
                  href={`https://account.${rawURL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-cy="buy-now"
                >
                  <Button fill>Buy Now!</Button>
                </a>
              </Plan.Box>
              <Plan.Divider />
              <Plan.Box>
                <Plan.Name>P.I.G.I. ENTERPRISE</Plan.Name>
                <Plan.Cost>CONTACT US</Plan.Cost>
                <Plan.Desc>
                  For large corporate or government users. Give your
                  organisation a scalable, customised serious competitive
                  advantage.
                </Plan.Desc>
                <Plan.List>
                  <ul>
                    <li>All the benefits of casual</li>
                    <li>24/7 Phone & email support</li>
                    <li>Full machine learning diagnostics</li>
                    <li>Training support</li>
                    <li>Custom platform integration</li>
                  </ul>
                </Plan.List>
                <Link to="/contact-us">
                  <Button ghost fill>
                    Contact Us
                  </Button>
                </Link>
              </Plan.Box>
            </Plan.Columns>
          </Plan>
          <CallToAction
            header="Need more than software?"
            linkText="Saber can support you at every stage of your mission"
            to="/service-overview/"
          />
        </Container.Dark>
      </Layout>
    </>
  );
}

export default function (): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              rawURL
            }
          }
        }
      `}
      render={({ site }) => <PIGI site={site} />}
    />
  );
}
