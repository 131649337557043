import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-flow: column;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.grey200};
  font-family: ${({ theme }) => theme.fontHeader};
  text-align: center;
`;

const Cost = styled.div`
  color: ${({ theme }) => theme.primary500};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  font-weight: bold;

  margin-top: 0.75rem;

  text-align: center;

  .small {
    font-size: 0.75rem;
    font-weight: normal;
  }
`;

const Desc = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontBody};
  margin: 1rem 0;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.grey700};
  margin: 2rem auto;

  height: 2px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    margin: auto;
    height: 100%;
    width: 2px;
  }
`;

const List = styled.div`
  color: white;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fontHeader};
  margin-bottom: 1rem;

  ul {
    list-style: none;
    counter-reset: custom-counter 0;
    padding: 0;
  }

  ul li {
    counter-increment: custom-counter;
    margin: 0.75rem 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
  }

  ul li::before {
    color: ${({ theme }) => theme.success400};
    font-size: 1.75rem;
    content: '✔';

    --size: 32px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: var(--size);
    height: var(--size);

    margin-right: 0.25rem;
  }

  @media only screen and (min-width: 1200px) {
    ul li {
      font-size: 1rem;
    }
  }
`;

const Columns = styled.div`
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 64px 1fr;
  }
`;

const Wrapper = styled.div`
  background: #202020;
  margin: auto;
  padding: 2rem 1rem;

  @media only screen and (min-width: 768px) {
    padding: 2rem;
  }
`;

export default Object.assign(Wrapper, {
  Name,
  Box,
  Columns,
  Cost,
  Desc,
  Divider,
  List,
});
