import styled from 'styled-components';

export const Icon = styled.div`
  border: 4px solid ${({ theme }) => theme.primary500};
  border-radius: 100%;
  color: ${({ theme }) => theme.grey300};

  font-size: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;

  margin-right: 1rem;

  svg path {
    stroke: ${({ theme }) => theme.grey300};
  }

  @media only screen and (min-width: 500px) {
    font-size: 3rem;

    height: 96px;
    width: 96px;
    min-height: 96px;
    min-width: 96px;
  }

  @media only screen and (min-width: 1024px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 1200px) {
    height: 124px;
    width: 124px;
    min-height: 124px;
    min-width: 124px;
  }
`;

export const Text = styled.div`
  flex-grow: 1;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.875rem;
  font-weight: 500;

  letter-spacing: 1px;

  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1.125rem;
  }
`;

export const Subheader = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 0.875rem;

  @media only screen and (min-width: 1024px) {
    text-align: center;
    font-size: 0.875rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  width: 100%;

  @media only screen and (min-width: 1024px) {
    flex-flow: column;
  }
`;
