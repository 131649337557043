import React from 'react';
import styled from 'styled-components';
import Container from 'src/Components/ContentContainer';

import { Gap } from 'src/page-styles/base.styled';

const Video = styled.video`
  width: 100%;
`;

function VideoPlayer(): JSX.Element {
  return (
    <Container.Dark>
      <Gap />
      <Video autoPlay={true} controls={false} loop={true} muted={true}>
        <source src="https://assets.saberastro.com/videos/pigi.mp4" />
      </Video>
      <Gap />
    </Container.Dark>
  );
}

export default VideoPlayer;
