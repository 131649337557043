import React from 'react';
import { Typography } from 'amphitheatre';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Container from '../ContentContainer';

import * as Base from 'src/page-styles/base.styled';

const { Header } = Typography;

const PIGIProgress = keyframes`
  0% {
    width: 0px;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 18.25%;
  }
`;

const OtherProgress = keyframes`
0% {
  width: 0px;
}

50% {
  width: 80%;
}

100% {
  width: 100%;
}
`;

const ProgressWrapper = styled.div`
  position: relative;
  height: 32px;
`;

const ProgressTitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-weight: bold;
  padding-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  z-index: 2;
`;

const ProgressBar = styled.div`
  animation: ${PIGIProgress} 5s linear forwards;
  animation-play-state: paused;
  background: rgb(251, 146, 60);
  background: linear-gradient(
    90deg,
    rgba(251, 146, 60, 1) 0%,
    rgba(250, 204, 21, 1) 100%
  );
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const ProgressBarOther = styled(ProgressBar)`
  animation: ${OtherProgress} 5s linear forwards;
  animation-play-state: paused;
`;

const Progression = styled.div`
  &.playing {
    ${ProgressBar} {
      animation-play-state: running;
    }
    ${ProgressBarOther} {
      animation-play-state: running;
    }
  }
`;

function MovingBar(): JSX.Element {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <Container.Dark style={{ overflowX: 'hidden' }}>
      <Base.Gap />
      <Base.Heading style={{ textAlign: 'left' }}>
        Gentle learning curve
      </Base.Heading>
      <Base.Paragraph>
        Saber offers full technical and training support for P.I.G.I. With our
        help, your operator could be fully proficient in P.I.G.I. with{' '}
        <b>
          <i>hours</i>
        </b>
        , with alternative software taking weeks potentially months.
      </Base.Paragraph>
      <Header level={3}>P.I.G.I.</Header>
      <Progression className={inView ? 'playing' : ''} ref={ref}>
        <ProgressWrapper>
          <ProgressTitle>Few hours</ProgressTitle>
          <ProgressBar />
        </ProgressWrapper>
        <br />
        <Header level={3}>Other Software</Header>
        <ProgressWrapper>
          <ProgressTitle>Weeks +</ProgressTitle>
          <ProgressBarOther />
        </ProgressWrapper>
      </Progression>
      <Base.Gap />
    </Container.Dark>
  );
}

export default MovingBar;
