import React from 'react';
import { Icon, Header, Subheader, Text, Wrapper } from './IconText.styled';

interface Props {
  icon: React.ReactNode;
  header: string;
  subheader?: string;
}

/**
 * Displays a component with an icon and text.
 *
 * @param {React.ReactNode} icon The icon to display
 * @param {string} header The title of the icon
 * @param {string} subheader The body of content to explain the icon
 */
const IconText: React.FunctionComponent<Props> = ({
  icon,
  header,
  subheader,
}: Props) => {
  return (
    <Wrapper>
      <Icon>{icon}</Icon>
      <Text>
        <Header>{header}</Header>
        <Subheader>{subheader}</Subheader>
      </Text>
    </Wrapper>
  );
};

export default IconText;
